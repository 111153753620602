#about {
  background-color: yellow;
}
.card-text {
  text-align: justify;
  padding: 10px;
  width: auto;
  height: 90%;
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
}

/*.card {
  border: none;
}*/

.about_image {
  border: none;
  /* margin-top: 10%; */
  /* height: 80%; */
  width: auto;
}
.image2 {
  width: 100%;
  height: 100%;
  align-items: center;
  border: 5px solid black;
}

.text {
  /* margin-top: 4%; */
}
.title {
  text-decoration: underline;
}

.about_readmore {
  outline: none;
  font-weight: 500;
  background: #006400;
  transition: 0.5s;
  color: whitesmoke;
  padding: 1px;
  width: 175px;
  height: auto;
  align-self: flex-end;
}
.about_readmore:hover {
  background: black;
  color: white;
}
.about_readless {
  font-weight: 500;
  background: white;
  transition: 0.5s;
  color: black;
  padding: 2px 20px;
  width: 150px;
  align-self: center;
}
.about_readless:hover {
  background: black;
  color: white;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .card-text {
    text-align: justify;
    padding: 5px;
    width: auto;
    height: 90%;
    font-size: 16px;
    font-weight: 500;
  }
  .about_readmore {
    align-self: center;
    width: auto;
  }
  .modalMedisureI {
    font-size: 20px !important;
  }
}
