.home{
    width: 100%;
    padding-top: 60px;

}
.home_image{
    width: 100%;
    /* position: relative; */
}
.homeimg{
    max-width: 100%;
}
.home_values{
    background: #e9ecef;
}
.home_values_content{
    margin: 0 auto;
  width: 80%;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  grid-gap: 18px;


}
.page_title{
    padding-top: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 25px;
}
.about{
    /* background: lightgreen; */
}
.about_content{
    margin: 20px;
}
.services{
    background: #e9ecef;
    position: relative;
    /* filter: blur(1px); */
    /* color: white; */
    /* background-color: rgba(0, 0, 0, 0.637); */
}
/* .services::after{
    content:'';
    background: url(./pagesimages/plant.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    color: white;
    position: absolute;
    top:0px;
    left: 0px;
    width:100%;
    height:100%;
    z-index:-1;
    opacity: 0.8;
} */
.services_content{
    margin: 0 auto;
  width: 80%;
  margin-top: -30px;
  padding-bottom: 50px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 18px;
}
.gallery{
    /* background: lightpink; */
}
.gallery_content{
    width: 80%;
    margin: 50px auto;
}
.item{

}
.item_image{
    /* padding: 50px; */
    border: 5px solid black;
    border-radius: 5px;

}
.titlehr{
    width: 5%;
    margin: 0 auto;
}
.gallery_content_mob{
    display: none;
}
.documents{
    width: 100%;
    /* background: #e9ecef; */

}
.documents_content{
    display: flex;
    width: 80%;
    margin: 0px auto;
    padding-bottom: 50px;
    margin-top: 30px;

}
.documents_content_pdf{
    border: 2px solid black;
    padding: 15px;
    width: 33%;
    margin: 20px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}
.contact{
    margin-bottom: 50px;
}
@media screen and (max-width: 768px) and (orientation: portrait) {

    .gallery_content{
        display: none;
    }
    .gallery_content_mob{
        width: 90%;
        margin: 30px auto;
        display: block;
    }
    .titlehr{
        width: 20%;
        margin: 0 auto;
    }
    .services{
        background: #e9ecef;
        color: black;
    }
    .documents_content{
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: 0px auto;
        padding-bottom: 50px;
        margin-top: 10px;
    
    }
    .documents_content_pdf{
        border: 2px solid black;
        padding: 15px;
        width: 100%;
        margin: 0px;
        margin-top: 15px;
        text-align: center;
        font-weight: bold;
    }
}