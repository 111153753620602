@media only screen and (max-width: 768px) {
  .open {
    float: right;
    margin-top: 10px;
    align-items: center;
    text-align: center;
  }
  .delete {
    float: left;
    margin-top: 10px;
  }
}

.clientmain {
  border: 3px solid black;
  box-shadow: 5px gray;
  padding: 15px 20px;
  margin: 10px;
  background-color: #e0e0e0;
}
.vert {
  border: 1px solid black;
  height: auto;
  padding: 0px;
  margin: 0px;
}

.open {
  background: #006400;
  color: whitesmoke;
  align-items: right;
  padding: 3px;
  width: 220x;
  margin: 10px;
}

.delete {
  align-items: left;
  color: whitesmoke;
  padding: 3px;
  margin: 10px;
}
