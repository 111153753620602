.client {
  padding-top: 60px;
  font-family: "Arimo", sans-serif;
}
.client_header {
  margin-right: 125px;
  margin-left: 125px;
  width: auto;
  height: auto;
}
.client_header_title {
  font-size: 25px;
  text-align: center;
  font-weight: 500;
  padding: 25px;
}
.client_header_content {
  font-size: 25px;
}
.client_header_content_div {
  text-align: center;
  padding: 10px;
  font-weight: bold;
  margin-left: 25 px;
  margin-right: 30px;
}
.client-footer_title {
  text-align: left;
  font-weight: bold;
}
.client_header_content_buttons {
  padding: 10px;
  display: flex;
  justify-content: space-around;
}
table,
th,
td {
  border: 1px solid black;
}
.welcome {
  font-size: 30px;
}
.phone {
  font-size: 25px;
}
.card {
  margin: 0 auto;
  float: none;
  margin-bottom: 10px;
  border: 4px solid black;
  background-color: #e0e0e0;
}

.btn-2 {
  margin-bottom: 20px;
  font-size: large;
  margin-top: 6px;
  width: auto;
  padding-right: 5px;
}
.choose2 {
  margin-top: 140px;
  margin: auto;
  width: 10px;
}
.row {
  display: flex;
}
.btnc {
  height: 35px;
  padding-top: 1px;
  padding-bottom: 1px;
}
@media only screen and (max-width: 768px) {
  .client {
    font-size: small;
  }
  .style2 {
    margin-left: 0%;
    font-size: small;
  }
  .btnc {
    padding-right: 1px;
    padding-left: 1px;
    padding: 1px;
    margin-right: 15px;
    margin-left: 15px;
    width: 150px;
    height: auto;
    align-items: center;
    text-align: center;
  }

  .choose2 {
    margin-left: 30px;
    width: 225px;
    align-items: center;
    height: auto;
  }
  .btn-2 {
    align-items: center;
    text-align: center;
    padding: 0px;
    width: 225px;
    height: 35px;
    margin-left: 30px;
  }
  .btn-title {
    font-size: 18px;
  }
  .phone {
    font-size: 16px;
    font-weight: 500;
  }
  .card {
    padding: 5px;
  }
  .heading2 {
    font-size: 21px;
    font-weight: bolder;
  }
  .heading3 {
    font-size: 18px;
    font-weight: 900;
  }
  .email {
    font-size: 15px;
  }
  .designation {
    font-size: 15px;
    font-weight: 500;
  }
}
