.title {
  margin-top: 7px;
  color: #006400;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.btn {
  font-size: large;
  margin-bottom: 10px;
}
.btnr {
  background-color: #006400;
  color: whitesmoke;
}

.Single_header_content_buttons {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  margin-left: 0px;
  float: left;
}
.choose {
  align-items: center;
  display: flex;
  height: 30px;
  font-size: medium;
  margin-top: 0px;
}
.btn-1 {
  padding: 10px;
  margin-bottom: 0px;
  font-size: large;
  margin-top: 6px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}
.Box {
  height: 350px;
  overflow: auto;
}
.Box > div {
  text-decoration: none;
  color: black;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 4px dimgrey;
  padding: 2px 5px;
  text-transform: capitalize;
  font-weight: 400;
  margin: 7px 0;
}
.head {
  text-align: center;
  font-weight: bolder;
  font-size: medium;
}
.details {
  /*margin: 10px;
  padding: 10px;*/
  margin-bottom: 0px;
  color: #006400;
}
.style {
  font-family: "Arimo", sans-serif;
}

@media only screen and (max-width: 768px) {
  .btnr {
    padding: 0.5px;
    margin-right: 25px;
    width: 195px;
    height: auto;
    font-size: small;
  }
  .choose {
    font-size: small;
    width: 110px;
    margin-top: 10px;
  }
  .center {
    margin-left: 8px;
    margin-top: 10px;
  }
  .style {
    padding: 0%;
  }
  .designation {
    font-size: 15px;
  }
}
@media only screen and (max-width: 1580px) and (min-width: 800px) {
  .btn-1 {
    margin-right: 570px;
  }
  .choose {
    margin-left: 20px;
    margin-right: 40px;
    width: 117px;
  }
  .center {
    margin-left: 10px;
  }
}
.title {
  padding: 50px;
  margin-top: 0px;
  text-align: center;
  font-weight: bold;
}
