.pcb {
  padding-top: 80px;
}
.pcb_header_title {
  font-size: 25px;
  text-align: center;
  font-weight: 500;
  padding: 25px;
}
.pcb_header_content {
  font-size: 25px;
  font-family: "Arimo", sans-serif;
}
.welcome {
  font-size: 30px;
}
.pcb_header_content_buttons {
  padding: 2px;
  display: flex;
  justify-content: space-around;
  margin-left: 5px;
  float: left;
}

.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
  border: 4px solid black;
  background-color: #e0e0e0;
  padding: 20px;
}
.contact {
  font-size: 30px;
}
.name {
  font-size: 30px;
}
.btnp {
  text-align: center;
  padding: 2px;
  text-align: center;
  height: 45px;
  font-size: medium;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .btnp {
    font-size: 13px;
    text-align: center;
    align-items: center;
    width: 200px;
    height: 45px;
    padding: 0px;
    margin-right: 5px;
    margin-left: 5px;
  }
  .pcb_header_content_buttons {
    font-size: small;
  }
  .contact {
    font-size: 14px;
  }
  .name {
    font-size: 20px;
  }
  .pcbheading {
    margin-left: 0px;
    padding-left: 0%;
    font-size: 25px;
    align-items: left;
    font-weight: bolder;
  }
}
