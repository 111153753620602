.login{
    padding-top: 80px;
    height: 80vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login_container{
    margin: 50px;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 1px 1px 10px 1px rgb(116, 221, 240);
    border: 2px solid black;
}
.login_container_title{
    font-weight: bold;
    text-align: center;
}
.login_container_input{
    margin: 10px;
}
.mainLoginButton{
    background-color: white;
    text-align: center;
    color: black;
    font-weight: bold;
    transition: 0.3s all ease-in-out;
    padding: 2px 15px;
    border-radius: 5px;
}
.mainLoginButton:hover{
    background-color: black;
    text-align: center;
    color: white;
    font-weight: bold;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
    .login_container{
        padding: 10px;
        margin: 10px;
    }
}