.my-form {
  color: #305896;
}
.my-form .btn-default {
  background-color: #305896;
  color: black;
  border-radius: 0;
}
.my-form .btn-default:hover {
  background-color: #4498c6;
  color: black;
}
.my-form .form-control {
  border-radius: 0;
}
ipad {
  padding: 10px;
}
.stylec {
  font-size: 15px;
}
.btn3 {
  align-items: center;
  text-align: center;
  width: auto;
  height: 40px;
  color: whitesmoke;
  padding: 0px;
}
.send {
  font-size: 20px;
}
@media only screen and (max-width: 768px) {
  .stylec {
    font-size: 15px;
  }
}
