.root1{
    /* width: 358px; */
    /* height:400px; */
    margin-top:50px;
    z-index: 2;
    position: relative;
    /* border: 1px solid rgba(0, 0, 0, 0.603); */
    /* transition: all ease 2s !important; */
}
.root_container1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
padding: 20px 20px 40px 20px;
        
}
.root_logo1{
    height: 124px;
    width: 124px;
}
.root_title1{
    font-weight: bold;
font-size: 18.9166px;
line-height: 26px;
/* or 139% */

text-align: center;

color: #10182F;
margin: 20px 0px;

}
.root_content1{
    font-weight: normal;
font-size: 16.8148px;
line-height: 26px;
/* or 156% */
text-align: justify;
color: #10182F;
}

@media screen and (max-width: 768px) and (orientation: portrait) {
    .root_container1{
        padding: 20px 10px;    
    }
    .root_logo1{
        height: 100px;
        width: 100px;
    }
    .root_title1{
        font-weight: bold;
font-size: 11.7713px;
line-height: 16px;


color: #10182F;

    margin: 5px 0px;
    
    }
    .root_content1{
        font-weight: normal;
font-size: 10.4633px;
line-height: 16px;

text-align: center;

color: #10182F;

    }
}
