.choose1 {
  margin-top: 5px;
  margin-left: 165px;
  margin-bottom: 90px;
  width: 125px;
}
.select {
  margin-top: 5px;
  color: blue;
}
.btn1 {
  margin-top: 5px;
  margin-left: 75px;
  margin-bottom: 90px;
  height: 40px;
}
.black {
  color: black;
}
@media only screen and (max-width: 762px) {
  .btn1 {
    padding-right: 20px;
    margin-right: 80px;
    height: auto;
    width: 200px;
    margin-top: 5px;

    align-items: center;
    margin-bottom: 20px;
  }
  .choose1 {
    font-size: medium;
    width: 150px;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 75px;
    text-align: center;
    align-items: center;
  }
  .heading {
    font-size: 35px;
    font-weight: bolder;
  }
}
