.admin {
  padding-top: 80px;
}
.admin_header_title {
  font-size: 25px;
  text-align: center;
  font-weight: 500;
  padding: 25px;
}
.admin_header_content {
  font-size: 25px;
}
.welcome {
  font-size: 30px;
}
.admindetails {
  font-family: "RocknRoll One", sans-serif;
  margin-top: 0px;
}
.admin_header_content_buttons {
  padding: 10px;
  display: flex;
  justify-content: space-around;
  margin-left: 0px;
  float: left;
}

.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
  border: 4px solid black;
  background-color: #e0e0e0;
  padding: 20px;
}
.contacta {
  font-size: 25px;
}

@media only screen and (max-width: 640px) {
  .Welcome {
    font-size: 40px;
  }
  .btn-2 {
    margin-left: 70px;
    width: 25px;
    height: 10px;
    margin-top: 10px;
    padding: 1px;
    align-items: center;
    text-align: center;
  }

  .admindetails {
    font-family: "Arimo", sans-serif;
  }
  .name {
    font-size: xx-small;
  }
  .contacta {
    font-size: 17px;
    font-style: bolld;
  }
  .admin_header_content {
    padding: 0px;
  }
  .admindetails {
    margin-top: 0px;
    padding: 1px;
    padding-left: 0%;
    font-size: 16px;
  }
  .adminHeading {
    font-size: 25px;
    padding-left: 5px;
  }
  .admin_header_title {
    font-size: x-small;
  }
}
