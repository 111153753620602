.root{
    margin-top:50px;
    z-index: 2;
    position: relative;
    min-height: 403px;
}
.cardwcu_bottom{
    display: flex; 
    justify-content: flex-end;
}
.root_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 40px 20px;        
}
.root_logo{
    height: 124px;
    width: 124px;
}
.root_title{
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #10182F;
    margin: 20px 0px;
}
.root_content{
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    text-align: justify;
    color: #10182F;
}

.readMoreCSS{
    margin-top: 0;
    width: 100vw;
    height: calc(100vh - 69px);
    position: fixed;
    top: 69px;
    left: 0;
    z-index: 10000;
    animation: grow 1.5s;
}

@keyframes grow{
    0%{
        opacity: 1;
        transform: scale(0) ;
    }
    /* 50%{
        opacity: 1;
        transform: scale(.6) ;
    }
    90%{
        opacity: .9;
        transform: scale(1.2) ; 
    } */
    100%{
        margin-top: 0;
        width: 100vw;
        height: calc(100vh - 76px);
        position: fixed;
        top: 76px;
        left: 0;
        z-index: 10000;
    }
}

.RM_btn{
    padding: 4px 12px;
    box-shadow: 0 0 4px grey;
    font-weight: 700;
    border: 1px solid;
    letter-spacing: 0.3px;
    transition: all ease-out .5s;
}
.RM_btn:hover{
    box-shadow: 0 0 8px grey;
    border: 1px solid transparent;
}

.btn_div{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

@media only screen and (min-width: 1024px) and (max-width: 1426px){
}

@media screen and (max-width: 768px) and (orientation: portrait) {
    /* html{
        font-size: 70%;
    } */
    .root{
        min-height: 250px;
    }
    .root_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;    
    }
    .root_logo{
        height: 100px;
        width: 100px;
    }
    .root_title{
        font-weight: bold;
        font-size: 11.7713px;
        line-height: 16px;
        color: #10182F;
        margin: 5px 0px;
    }
    .root_content{
        font-weight: normal;
        font-size: 10.4633px;
        line-height: 16px;
        text-align: center;
        color: #10182F;
    }
}